import axios from 'redaxios'
import {useFormStore} from '../stores'

export async function getApplication() {
	const url = new URL(document.location)
	const store = useFormStore()

	if (!url.searchParams.has('uid')) return

	const query = `
		query GetApplication($uid: [String]) {
			entry(uid: $uid) {
				... on applications_application_Entry {
					acceptTermsAndConditions
					additionalIncome{
						... on additionalIncome_additionalIncome_BlockType {
							additionalIncomeAmount
							additionalIncomeFrequency
							additionalIncomeType
							id
						}
					}
					applicant2Email
					applicant2Name
					applicant2Phone
					applicant2Relationship
					applicationCompleted
					applicationMaxStep
					applicationStep
					applicationStep
					assets {
						... on assets_asset_BlockType {
							id
							assetType
							assetValue
						}
					}
					creditCards {
						... on creditCards_creditCard_BlockType {
							creditCardIssuer
							creditCardLimit
							creditCardOwing
							creditCardType
							id
						}
					}
					currentAddress
					currentAddressMonths
					currentAddressYears
					currentEmployer
					currentEmploymentMonths
					currentEmploymentType
					currentEmploymentYears
					currentLivingArrangement
					currentOccupation
					currentWageAmount
					currentWageFrequency
					dependents
					deposit
					depositAmount
					dob
					email
					firstName
					identificationDescription
					identificationType
					identificationUpload {
						... on uploads_Asset {
							filename
							id
							mimeType
							title
							url
						}
					}
					identificationUpload2 {
						... on uploads_Asset {
							filename
							id
							mimeType
							title
							url
						}
					}
					incomeUpload {
						... on uploads_Asset {
							filename
							id
							mimeType
							title
							url
						}
					}
					lastName
					licenseExpiry
					licenseNumber
					licenseVersion
					loanAmount
					loanPurpose
					loanRepaymentFrequency
					loanTerm
					loanType
					loans {
						... on loans_loan_BlockType {
							id
							loanAmount
							loanProvider
						}
					}
					maritalStatus
					middleName
					mortgageBalance
					phone
					previousAddress
					previousAddressMonths
					previousAddressYears
					previousEmployer
					previousEmploymentMonths
					previousEmploymentType
					previousEmploymentYears
					previousOccupation
					propertyValue
					referrer
					rentMortgageAmount
					rentMortgageFrequency
					residencyStatus
					uid: uid
					visaExpiryDate
				}
			}
		},
	`

	const variables = {
		uid: url.searchParams.get('uid'),
	}

	const {data} = await axios({
		method: 'post',
		url: '/api',
		data: {
			query,
			variables,
		},
		validateStatus: (status) => {
			return status === 200 || status === 404
		},
	})

	if (data.errors) {
		console.log(query, variables)
		throw new Error(data.errors.map((e) => e.debugMessage).join('\n'))
	}

	const entry = data?.data?.entry
	// console.log(entry)

	if (!entry) return

	for (const [key, value] of Object.entries(entry)) {
		if (!(key in store)) continue
		if (value === 'null') continue

		store[key] = value
	}
}
