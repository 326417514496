import axios from 'redaxios'
import {useFormStore} from '../stores'

export async function saveApplication() {
	const url = new URL(document.location)
	const store = useFormStore()

	const query = `
		mutation SaveApplication(
			$additionalIncome: additionalIncome_MatrixInput,
			$assets: assets_MatrixInput,
			$creditCards: creditCards_MatrixInput,
			$identificationUpload: [Int],
			$identificationUpload2: [Int],
			$incomeUpload: [Int],
			$loans: loans_MatrixInput,
		) {
			save_applications_application_Entry(
				acceptTermsAndConditions: ${store.acceptTermsAndConditions}
				additionalIncome: $additionalIncome,
				applicant2Email: "${store.applicant2Email}"
				applicant2Name: "${store.applicant2Name}"
				applicant2Phone: "${store.applicant2Phone}"
				applicant2Relationship: "${store.applicant2Relationship}"
				applicationCompleted: ${store.applicationCompleted}
				applicationMaxStep: ${store.applicationMaxStep}
				applicationStep: ${store.applicationStep}
				assets: $assets
				authorId: 1452,
				creditCards: $creditCards
				currentAddress: "${store.currentAddress}"
				currentAddressMonths: ${store.currentAddressMonths}
				currentAddressYears: ${store.currentAddressYears}
				currentEmployer: "${store.currentEmployer}"
				currentEmploymentMonths: ${store.currentEmploymentMonths}
				currentEmploymentType: "${store.currentEmploymentType}"
				currentEmploymentYears: ${store.currentEmploymentYears}
				currentLivingArrangement: "${store.currentLivingArrangement}"
				currentOccupation: "${store.currentOccupation}"
				currentWageAmount: ${store.currentWageAmount}
				currentWageFrequency: "${store.currentWageFrequency}"
				dependents: ${store.dependents}
				deposit: ${store.deposit}
				depositAmount: ${store.depositAmount}
				dob: "${store.dob}"
				email: "${store.email}"
				firstName: "${store.firstName}"
				identificationDescription: "${store.identificationDescription}"
				identificationType: "${store.identificationType}"
				identificationUpload: $identificationUpload
				identificationUpload2: $identificationUpload2
				incomeUpload: $incomeUpload
				lastName: "${store.lastName}"
				licenseExpiry: "${store.licenseExpiry}"
				licenseNumber: "${store.licenseNumber}"
				licenseVersion: "${store.licenseVersion}"
				loanAmount: ${store.loanAmount}
				loanPurpose: "${store.loanPurpose}"
				loanRepaymentFrequency: "${store.loanRepaymentFrequency}"
				loanTerm: "${store.loanTerm}"
				loanType: "${store.loanType}"
				loans: $loans
				maritalStatus: "${store.maritalStatus}"
				middleName: "${store.middleName}"
				mortgageBalance: ${store.mortgageBalance}
				phone: "${store.phone}"
				previousAddress: "${store.previousAddress}"
				previousAddressMonths: ${store.previousAddressMonths}
				previousAddressYears: ${store.previousAddressYears}
				previousEmployer: "${store.previousEmployer}"
				previousEmploymentMonths: ${store.previousEmploymentMonths}
				previousEmploymentType: "${store.previousEmploymentType}"
				previousEmploymentYears: ${store.previousEmploymentYears}
				previousOccupation: "${store.previousOccupation}"
				propertyValue: ${store.propertyValue}
				referrer: "${store.referrer}"
				rentMortgageAmount: ${store.rentMortgageAmount}
				rentMortgageFrequency: "${store.rentMortgageFrequency}"
				residencyStatus: "${store.residencyStatus}"
				uid: "${store.uid}"
				visaExpiryDate: "${store.visaExpiryDate}"
			) {
				uid
			}
		}
	`

	const variables = {
		additionalIncome: getMatrixData('additionalIncome', store.additionalIncome),
		assets: getMatrixData('asset', store.assets),
		creditCards: getMatrixData('creditCard', store.creditCards),
		identificationUpload: getUploadData(store.identificationUpload),
		identificationUpload2: getUploadData(store.identificationUpload2),
		incomeUpload: getUploadData(store.incomeUpload),
		loans: getMatrixData('loan', store.loans),
	}

	const {data} = await axios({
		method: 'post',
		url: '/api',
		data: {
			query,
			variables,
		},
		validateStatus: (status) => {
			return status === 200 || status === 404
		},
	})

	if (data.errors) {
		console.log(query, variables)
		throw new Error(data.errors.map((e) => e.debugMessage).join('\n'))
	}

	const entry = data?.data?.save_applications_application_Entry
	// console.log(entry)

	if (!entry) return

	store.$state.uid = entry.uid

	url.searchParams.set('uid', entry.uid)

	history.replaceState({}, '', url)

	function getMatrixData(blockName, items) {
		const matrixData = {
			blocks: [],
			sortOrder: [],
		}

		if (!blockName || !items) return matrixData

		items.forEach((item) => {
			const itemObj = {}
			itemObj[blockName] = JSON.parse(JSON.stringify(item))
			matrixData.blocks.push(itemObj)
			matrixData.sortOrder.push(item.id)
		})

		return matrixData
	}

	function getUploadData(items) {
		return items.map((item) => parseInt(item.id))
	}
}
