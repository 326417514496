import axios from 'redaxios'
import {RECAPTCHA_KEY, stringToHTML} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

class Form extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		const $forms = $('.form:not([data-init])')

		$forms.data('init', true)

		$forms.on('submit', async (e, form) => {
			e.preventDefault()

			this._disableForm(form)

			const isValid = await this._validateRecaptcha()

			if (isValid) {
				await this._submitFrom(form)
			}

			this._enableForm(form)
		})
	}

	onContentUpdate() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	async _submitFrom(form) {
		const $form = $(form)
		const $formGroups = $form.find('.form-group')
		const $formMessages = $form.find('.form-group__messages')
		const $formAlertSuccess = $form.find(`#${form.id}-alert-success`)
		const $formAlertError = $form.find(`#${form.id}-alert-error`)
		const formAction = $form.attr('action') || window.location.href
		const formMethod = $form.attr('method') || 'post'
		const formData = new FormData(form)

		// submit data
		const {data} = await axios({
			url: formAction,
			method: formMethod,
			data: formData,
			headers: {
				'Cache-Control': 'no-cache',
				'X-Requested-With': 'XMLHttpRequest',
				'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
			},
		})

		// handle success
		if (data.success) {
			$formAlertSuccess.removeClass('d-none')
			$formAlertError.addClass('d-none')
			$formGroups.removeClass('-error')
			$formMessages.empty()
			form.reset()
			return
		}

		// handle errors
		$formAlertSuccess.addClass('d-none')
		$formAlertError.removeClass('d-none')
		$formGroups.removeClass('-error')
		$formMessages.empty()

		for (const [name, message] of Object.entries(data.errors)) {
			const $group = $form.find(`#${form.id}-group-${name}`)
			const $messages = $group.find('.form-group__messages')

			$group.addClass('-error')
			$messages.append(stringToHTML(`<p>${message}</p>`))
		}
	}

	_validateRecaptcha() {
		return new Promise((resolve, reject) => {
			if (!RECAPTCHA_KEY) resolve(true)

			grecaptcha.ready(async () => {
				const token = await grecaptcha.execute(RECAPTCHA_KEY, {
					action: 'submit',
				})

				const {data} = await axios({
					url: `${window.location.origin}/actions/recaptcha-module/recaptcha/validate`,
					method: 'post',
					data: {
						token,
						CRAFT_CSRF_TOKEN: window.csrfTokenValue,
					},
				})

				if (data.success) {
					resolve(true)
				} else {
					resolve(false)
				}
			})
		})
	}

	_disableForm(form) {
		const $form = $(form)
		const $formSubmit = $form.find('[type="submit"]')

		$formSubmit.attr('disabled', true)
		$formSubmit.addClass('-loading')
	}

	_enableForm(form) {
		const $form = $(form)
		const $formSubmit = $form.find('[type="submit"]')

		$formSubmit.removeAttr('disabled')
		$formSubmit.removeClass('-loading')
	}
}

export default new Form()
