import Module from '../util/module'
import $ from '../util/query'

class FromRange {
	constructor(el) {
		this.$el = $(el)
		this.$input = this.$el.find('.form-range__input')
		this.$output = this.$el.find('.form-range__output')
		this.$track = this.$el.find('.form-range__lower-track')

		this.min = parseFloat(this.$input.prop('min')) || 0
		this.max = parseFloat(this.$input.prop('max')) || 100
		this.step = parseFloat(this.$input.prop('step')) || 1
		this.range = this.max - this.min

		this._addSteps()
		this._update()
		this.$input.on(['change', 'input'], () => this._update())
	}

	_addSteps() {
		let count = 1

		if (this.range !== 100 || this.step !== 1) {
			count = this.range / this.step
		}

		for (let i = 0; i < count; i++) {
			const span = document.createElement('span')
			this.$track.append(span)
		}
	}

	_update() {
		this.val = parseFloat(this.$input.prop('value'))
		const percent = ((this.val - this.min) / this.range) * 100

		this.$el.style('--form-range-offset', `${percent}%`)
		this.$output.prop('value', this.val)
	}
}

class FormRangeModule extends Module {
	setup() {
		const $rangeInputs = $('[data-range]')

		$rangeInputs.forEach((el) => {
			new FromRange(el)
		})
	}

	onNavEnd() {
		this.setup()
	}
}

export default new FormRangeModule()
