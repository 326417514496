import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'

window.dataLayer = window.dataLayer || []
window.addEventListener('DOMContentLoaded', () => initForm())
window.addEventListener('content-updated', () => initForm())

function initForm() {
	if (document.body.classList.contains('apply-page')) {
		// prettier-ignore
		createApp(App)
			.use(createPinia())
			.mount('#application-form')
	}
}
