import {gsap} from 'gsap'
import {$BODY} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

class HeaderModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.$header = $('#page-header')
		this.$nav = $('#main-nav')
		this.$navToggle = $('#nav-toggle')
		this.$applyBtn = this.$header.find('.header__apply-btn')
		this.$dropdownToggles = this.$header.find('[data-dropdown]')
		this.$activeDropdown = null
		this.animating = false

		$BODY.on('click', (e) => {
			const $target = $(e.target)

			if (!$target.closest('#page-header').any) {
				this._closeActiveDropdown()
			}
		})

		this.$dropdownToggles.on('click', (e, el) => {
			e.preventDefault()

			const $toggle = $(el)
			const $dropdown = $($toggle.data('dropdown'))

			this._toggleDropdown($dropdown)
		})

		this.$navToggle.on('click', (e, el) => {
			e.preventDefault()
			this._toggleNav()
		})
	}

	onNavStart() {
		this._closeActiveDropdown()
		this._closeNav()
	}

	onResizeX() {
		this._closeActiveDropdown()
		this._closeNav()
	}

	onKeydown(e) {
		if (e.detail === 'Escape') {
			this._closeActiveDropdown()
			this._closeNav()
		}
	}

	// ----------------------------------------
	// Dropdown
	// ----------------------------------------

	async _toggleDropdown($dropdown) {
		if (this.animating) return

		if (this.$activeDropdown && this.$activeDropdown.prop('id') !== $dropdown.prop('id')) {
			await this._closeDropdown($activeDropdown)
		}

		if ($dropdown.hasClass('-active')) {
			await this._closeDropdown($dropdown)
		} else {
			await this._openDropdown($dropdown)
		}
	}

	async _openDropdown($dropdown) {
		if (this.animating) return

		$dropdown.style('height', 'auto')
		$dropdown.addClass('-active')
		this.$activeDropdown = $dropdown
		this.animating = true
		this._updateToggleState($dropdown)

		const height = $dropdown.style('height')

		await gsap
			.timeline()
			.from(
				$dropdown,
				{
					height: 0,
				},
				0,
			)
			.to(
				this.$applyBtn,
				{
					y: height,
				},
				0,
			)

		this.animating = false
	}

	async _closeDropdown($dropdown) {
		if (this.animating) return

		this.animating = true

		await gsap
			.timeline()
			.to(
				$dropdown,
				{
					height: 0,
				},
				0,
			)
			.to(
				this.$applyBtn,
				{
					y: 0,
				},
				0,
			)

		$dropdown.removeClass('-active')
		this.$activeDropdown = null
		this.animating = false
		this._updateToggleState($dropdown)
	}

	async _closeActiveDropdown() {
		if (this.$activeDropdown) {
			await this._closeDropdown(this.$activeDropdown)
		}
	}

	_updateToggleState($dropdown) {
		this.$dropdownToggles.forEach((toggle) => {
			const dropdownId = toggle.dataset.dropdown.replace('#', '')

			if ($dropdown.hasClass('-active') && $dropdown.prop('id') === dropdownId) {
				toggle.classList.add('-active')
			} else {
				toggle.classList.remove('-active')
			}
		})
	}

	// ----------------------------------------
	// Nav
	// ----------------------------------------

	_toggleNav() {
		if (this.$navToggle.hasClass('-active')) {
			this._closeNav()
		} else {
			this._openNav()
		}
	}

	_openNav() {
		$BODY.addClass('nav-open')
		this.$nav.addClass('-active')
		this.$navToggle.addClass('-active')
	}

	_closeNav() {
		$BODY.removeClass('nav-open')
		this.$nav.removeClass('-active')
		this.$navToggle.removeClass('-active')
	}
}

export default new HeaderModule()
