import {gsap} from 'gsap'
import {MotionPathPlugin} from 'gsap/MotionPathPlugin'
import {SplitText} from 'gsap/SplitText'
import {$BODY, isLG} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

gsap.registerPlugin(MotionPathPlugin, SplitText)

// const ease = CustomEase.create('custom', 'M0,0 C0.532,0 0.664,1.222 1,1 ')

class HomeLandingModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		if (!$BODY.hasClass('home-page')) return

		this.$landing = $('.landing')
		this.$slides = $('.landing-slide')
		this.$polygon = this.$landing.find('.landing__polygon')
		this.$path1 = this.$landing.find('.landing__path-1')
		this.$path2 = this.$landing.find('.landing__path-2')
		this.$pagination = this.$landing.find('.pagination__dot')
		this.slideIndex = 0
		this.slideCount = this.$slides.length - 1
		this.timeout
		this.interval
		this.isAnimating = false

		this.$slides.forEach((slide, i) => {
			const title = slide.querySelector('.landing-slide__title')

			new SplitText(title, {
				type: 'lines,words',
				linesClass: 'line',
				wordsClass: 'word',
				charsClass: 'char',
			})

			if (i === 0) {
				slide.classList.add('-active')
			} else {
				slide.style.display = 'none'
			}
		})

		this.$pagination.on('click', async (e, el) => {
			e.preventDefault()

			if (this.interval) {
				clearInterval(this.interval)
			}

			await this._toSide(parseInt(el.dataset.index))

			this.interval = setInterval(() => this._next(), 3000)
		})

		this.$landing.addClass('-active')

		this.timeout = setTimeout(
			() => {
				this._next()
				this.interval = setInterval(() => this._next(), 3000)
			},
			$BODY.hasClass('loader-visible') ? 6000 : 3000,
		)
	}

	onNavStart() {
		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		if (this.interval) {
			clearInterval(this.interval)
		}
	}

	onNavEnd() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	async _toSide(index) {
		if (this.isAnimating) return
		if (this.slideIndex === index) return

		this.isAnimating = true

		const $current = this.$slides.at(this.slideIndex)
		const $next = this.$slides.at(index)
		const tl = gsap.timeline({paused: true})

		this.$pagination.forEach((dot) => {
			parseInt(dot.dataset.index) === index ? dot.classList.add('-active') : dot.classList.remove('-active')
		})

		tl.add(this._tlSlideIn($next), 0)
		tl.add(this._tlSlideOut($current), 0)
		tl.duration(0.6)
		await tl.play()

		$current.removeClass('-active')
		$next.addClass('-active')

		this.slideIndex = index
		this.isAnimating = false
	}

	async _next() {
		const index = this.slideIndex + 1 <= this.slideCount ? this.slideIndex + 1 : 0
		await this._toSide(index)
	}

	_tlSlideIn($slide) {
		const $bg = $slide.find('.landing-slide__bg-img')
		const $image = $slide.find('.landing-slide__img')
		const $words = $slide.find('.landing-slide__title .word')
		const $btn = $slide.find('.btn-link > span')
		const timeline = gsap.timeline({paused: true})

		timeline.set($slide, {display: ''})
		timeline.set(this.$polygon, {transformOrigin: 'center'})

		timeline.fromTo(
			this.$polygon,
			{
				rotate: 0,
			},
			{
				duration: 1,
				rotate: 60,
			},
			0,
		)

		timeline.fromTo(
			$bg,
			{
				opacity: 0,
				scale: 0.55,
				rotate: -40,
			},
			{
				duration: 1.4,
				motionPath: {
					path: '.landing__path-1',
					align: '.landing__path-1',
					alignOrigin: [0.5, 0.5],
					autoRotate: false,
				},
				opacity: 1,
				rotate: isLG() ? -4 : 0,
				scale: 1,
			},
			0,
		)

		timeline.fromTo(
			$image,
			{
				opacity: 0,
				scale: 0.55,
				rotate: -40,
			},
			{
				duration: 1,
				motionPath: {
					path: '.landing__path-1',
					align: '.landing__path-1',
					alignOrigin: [0.5, 0.5],
					autoRotate: false,
				},
				opacity: 1,
				rotate: isLG() ? -4 : 0,
				scale: 1,
			},
			0,
		)

		timeline.fromTo(
			$words,
			{
				yPercent: 100,
			},
			{
				duration: 1,
				yPercent: 0,
			},
			0,
		)

		timeline.fromTo(
			$btn,
			{
				opacity: 0,
				yPercent: 30,
			},
			{
				duration: 0.5,
				opacity: 1,
				yPercent: 0,
			},
			0.5,
		)

		return timeline.play()
	}

	_tlSlideOut($slide) {
		const $bg = $slide.find('.landing-slide__bg-img')
		const $image = $slide.find('.landing-slide__img')
		const $words = $slide.find('.landing-slide__title .word')
		const $btn = $slide.find('.btn-link > span')
		const timeline = gsap.timeline({paused: true})

		timeline.set($slide, {display: ''})

		timeline.to(
			$image,
			{
				duration: 1,
				motionPath: {
					path: '.landing__path-2',
					align: '.landing__path-2',
					alignOrigin: [0.5, 0.5],
					autoRotate: false,
				},
				opacity: 0,
				scale: 0.55,
				rotate: 40,
			},
			0,
		)

		timeline.to(
			$bg,
			{
				duration: 0.9,
				motionPath: {
					path: '.landing__path-2',
					align: '.landing__path-2',
					alignOrigin: [0.5, 0.5],
					autoRotate: false,
				},
				opacity: 0,
				scale: 0.55,
				rotate: 40,
			},
			0,
		)

		timeline.fromTo(
			$words,
			{
				yPercent: 0,
			},
			{
				duration: 1,
				yPercent: 100,
			},
			0,
		)

		timeline.fromTo(
			$btn,
			{
				opacity: 1,
				yPercent: 0,
			},
			{
				duration: 0.5,
				opacity: 0,
				yPercent: 30,
			},
			0,
		)

		timeline.set($slide, {display: 'none'})

		return timeline.play()
	}

	_tlPolygon() {
		const timeline = gsap.timeline({paused: true})

		timeline.to(this.$polygon, {
			ease: 'back.out(1.5)',
			duration: 1,
			rotate: 60,
			clearProps: true,
		})

		return timeline.play()
	}
}

export default new HomeLandingModule()
