import Swiper, {Autoplay, Pagination} from 'swiper'
import Module from '../util/module'
import $ from '../util/query'

Swiper.use([Autoplay, Pagination])

class Testimonials {
	constructor(el) {
		this.$el = $(el)
		this.$slider = this.$el.find('.swiper')
		this.$pagination = this.$el.find('.swiper-pagination')

		this.swiper = new Swiper(this.$slider.one, {
			loop: true,
			speed: 500,
			spaceBetween: 40,
			autoplay: {
				delay: 5000,
				pauseOnMouseEnter: true,
				disableOnInteraction: false,
			},
			pagination: {
				el: this.$pagination.one,
				clickable: true,
			},
		})
	}
}

class TestimonialsModule extends Module {
	setup() {
		const $testimonials = $('.testimonials')

		$testimonials.forEach((el) => new Testimonials(el))
	}

	onNavEnd() {
		this.setup()
	}
}

export default new TestimonialsModule()
