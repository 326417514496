import fitty from 'fitty'
import Module from '../util/module'
import $ from '../util/query'

class Calculator {
	constructor(el) {
		this.$el = $(el)
		this.$total = this.$el.find('.calculator__total > span')
		this.$approx = this.$el.find('.calculator__approx')
		this.$applyBtn = this.$el.find('.calculator__apply-btn > a')
		this.$form = this.$el.find('.calculator__form')
		this.$inputs = this.$form.find(['[name="amount"]', '[name="repayments"', '[name="term"]'])
		this.numberFormat = new Intl.NumberFormat('en-NZ', {style: 'currency', currency: 'NZD'})

		this._calculate()
		this.$inputs.on(['change', 'input'], () => this._calculate())

		fitty(this.$total.one, {
			minSize: 30,
			maxSize: 80,
			multiLine: false,
		})
	}

	_calculate() {
		const data = new FormData(this.$form.one)
		const amount = parseInt(data.get('amount'))
		const establishmentFee = parseFloat(data.get('establishment-fee'))
		const managementFee = parseFloat(data.get('management-fee'))
		const interestRate = parseFloat(data.get('interest-rate')) / 100
		const repayments = data.get('repayments')
		const term = parseInt(data.get('term'))

		if (isNaN(amount) || isNaN(term)) return

		// Validate amount
		if (amount < 1000) {
			this.$el.addClass('-error-amount')
			this.$total.text(this.numberFormat.format(0))
			return
		} else {
			this.$el.removeClass('-error-amount')
		}

		// Validate repayments
		let frequency

		switch (repayments) {
			case 'monthly':
				frequency = 12
				this.$approx.text('approximate monthly payment*')
				break

			case 'fortnightly':
				frequency = 26
				this.$approx.text('approximate fortnightly payment*')
				break

			case 'weekly':
				frequency = 52
				this.$approx.text('approximate weekly payment*')
				break
		}

		if (!frequency) return

		// Calculate repayments
		const periods = (term / 12) * frequency
		const interest = interestRate / frequency
		const total = (amount + establishmentFee + managementFee * term) * ((interest * (1 + interest) ** periods) / ((1 + interest) ** periods - 1))

		// Output formatted total
		this.$total.text(this.numberFormat.format(total))

		// Update apply button
		const url = this.$applyBtn.data('base-url')

		if (url) {
			this.$applyBtn.attr('href', `${url}?amount=${amount}&repayments=${repayments}&term=${term}`)
		}
	}
}

class CalculatorModule extends Module {
	setup() {
		const $calculators = $('.calculator')

		$calculators.forEach((el) => new Calculator(el))
	}

	onNavEnd() {
		this.setup()
	}
}

export default new CalculatorModule()
