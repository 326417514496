import {$WINDOW, isMD} from '../util/global'
import Module from '../util/module'
import AnimationFrame from '../util/animation-frame'

class SmoothScroll extends Module {
	setup() {
		// els
		this.html = document.documentElement
		this.body = document.body
		this.header = document.querySelector('#page-header')
		this.wrapper = document.querySelector('#page-wrapper')
		this.scroller = document.querySelector('#page-scroller')

		// props
		this.ease = isMD() ? 0.08 : 0.9
		this.top = 0
		this.bottom = 0
		this.direction = 'down'

		// bind update events
		this.resizeObserver = new ResizeObserver(() => this.updateHeight())
		this.resizeObserver.observe(this.wrapper)
		this.resizeObserver.observe(this.scroller)

		// create the render loop
		this.anim = new AnimationFrame(() => this.render(), 60)

		// set initial height
		this.updateHeight()

		// start the render loop
		this.anim.start()

		// the main el needs to "stick" to the screen and not scroll
		this.style(this.wrapper, {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		})
	}

	destroy() {
		// stop the render loop
		this.anim.stop()

		// reset inline styles
		this.html.style.height = 'auto'
		this.body.style.height = 'auto'
		this.wrapper.removeAttribute('style')
		this.scroller.removeAttribute('style')
	}

	onResize() {
		this.updateHeight()
	}

	onKeydown(e) {
		if (e.key === 'Tab') {
			setTimeout(() => this.scrollToActiveInput(), 100)
		}
	}

	render() {
		const prevPos = this.top < 1 ? 0 : this.top
		const nextPos = this.lerp(prevPos, this.scrollY, this.ease)
		const drag = this.drag(nextPos) // the difference between the scroll el's position and the window's scroll position

		if (drag === 0) return

		if (drag >= -1 && drag <= 1) {
			this.top = this.scrollY
		} else {
			this.top = nextPos
		}

		this.bottom = this.top + window.innerHeight
		this.direction = nextPos > prevPos ? 'down' : 'up'
		this.scroller.style.setProperty('transform', `translate3d(0px, ${(this.top >= 0 ? this.top : 0) * -1}px, 0px)`)

		$WINDOW.emit('scrolled')
	}

	resetScroll() {
		this.top = 0
		this.scrollY = 0
		this.scroller.removeAttribute('style')
	}

	enableScroll() {
		this.body.style.removeProperty('--scrollbar-width')
		this.body.style.overflow = ''
	}

	disableScroll(pos = null) {
		this.body.style.setProperty('--scrollbar-width', `${window.innerWidth - this.body.clientWidth}px`)
		this.body.style.overflow = 'hidden'

		if (pos !== null) {
			this.top = pos
			this.scrollY = pos
			this.scroller.style.transform = `translate3d(0, -${pos}px, 0)`
		}
	}

	updateHeight() {
		const height = this.scroller.getBoundingClientRect().height

		if (window.innerHeight > height) {
			this.html.style.height = `${window.innerHeight}px`
			this.body.style.height = `${window.innerHeight}px`
		} else {
			this.html.style.height = `${Math.round(height)}px`
			this.body.style.height = `${Math.round(height)}px`
		}
	}

	scrollToTop() {
		return this.scrollTo({x: 0, y: 0})
	}

	scrollToEl(el, offset = 0) {
		const elTop = this.scrollY + el.getBoundingClientRect().top
		const scrollOffset = this.header.getBoundingClientRect().height + offset
		const scrollPosition = elTop - scrollOffset

		return this.scrollTo({x: 0, y: scrollPosition})
	}

	scrollTo(position = {}) {
		return new Promise(async (resolve) => {
			this.scrollY = position.y || 0

			const interval = setInterval(() => {
				if (this.isScrolling) return

				clearInterval(interval)
				resolve(true)
			}, 100)
		})
	}

	scrollToActiveInput() {
		const target = document.activeElement

		if (!target) return
		if (!['INPUT', 'TEXTAREA', 'SELECT'].includes(target.nodeName)) return

		this.scrollToEl(target, 50)
	}

	// Utility
	// ----------------------------------------

	style(el, styles) {
		Object.assign(el.style, styles)
	}

	getProp(prop, el) {
		return getComputedStyle(el).getPropertyValue(prop)
	}

	lerp(a, b, n) {
		return (1 - n) * a + n * b
	}

	drag(nextPos) {
		return parseFloat((this.scrollY - nextPos).toFixed(2))
	}

	get scrollY() {
		return this.html.scrollTop
	}

	set scrollY(val) {
		this.html.scrollTop = val
	}

	get isScrolling() {
		return this.top !== this.scrollY
	}
}

export default new SmoothScroll()
