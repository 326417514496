import {gsap} from 'gsap'
import {$BODY, isMD} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

class LoaderModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	async setup() {
		const $loader = $('#page-loader')

		if (!$loader.any) {
			$BODY.addClass('loaded')
			$BODY.removeClass('loader-visible')
			return
		}

		$BODY.addClass('loader-visible')

		const $logo = $loader.find('.loader__logo')
		const $text = $loader.find('.loader__text')
		let rect

		gsap.set($text, {width: 'auto', height: 'auto'})
		rect = $text.rect()
		gsap.set($text, {width: '0px', height: '0px'})

		const tl = gsap.timeline({
			paused: true,
			delay: 1,
		})

		tl.add(() => $logo.addClass('-in'))

		if (isMD()) {
			tl.fromTo(
				$text,
				{
					width: '0px',
					height: 'auto',
				},
				{
					ease: 'back.out(0.6)',
					duration: 1,
					width: rect.width,
				},
			)
		} else {
			tl.fromTo(
				$text,
				{
					width: 'auto',
					height: '0px',
				},
				{
					ease: 'back.out(1)',
					duration: 1,
					height: rect.height,
				},
			)
		}

		tl.set($text, {
			width: 'auto',
			height: 'auto',
		})

		tl.to(
			$loader,
			{
				duration: 0.75,
				autoAlpha: 0,
			},
			'+=1',
		)

		if ($BODY.hasClass('home-page')) {
			const $landing = $(['#page-header', '.landing__container'])

			tl.from($landing, {
				duration: 0.75,
				autoAlpha: 0,
				clearProps: true,
			})
		}

		$BODY.addClass('loaded')

		await tl.play()

		$BODY.removeClass('loader-visible')
	}
}

export default new LoaderModule()
