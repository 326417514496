import {gsap} from 'gsap'
import {SplitText} from 'gsap/SplitText'
import Module from '../util/module'
import $ from '../util/query'

class TitleModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.observer = new IntersectionObserver(this._animate, {
			rootMargin: '0px 0px -20%  0px',
			threshold: 1.0,
		})

		const $titles = $('[data-title]')

		$titles.forEach((title) => {
			const text = new SplitText(title, {
				type: 'words,chars',
				wordsClass: 'word',
				charsClass: 'char',
			})

			gsap.set(text.words, {overflow: 'hidden'})
			gsap.set(text.chars, {yPercent: 100})
			this.observer.observe(title)
		})
	}

	onNavStart() {
		if (this.observer) {
			this.observer.disconnect()
		}
	}

	onNavEnd() {
		this.setup()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_animate(entries, observer) {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) return

			observer.unobserve(entry.target)

			const words = entry.target.querySelectorAll('.word')
			const delay = parseInt(entry.target.dataset.titleDelay) || 0
			const tl = gsap.timeline({delay, paused: true})

			words.forEach((word) => {
				const chars = word.querySelectorAll('.char')

				tl.to(
					chars,
					{
						duration: 0.35,
						yPercent: 0,
					},
					'>-0.25',
				)
			})

			tl.play()
		})
	}
}

export default new TitleModule()
