// @ts-check
import {defineStore} from 'pinia'

export const useFormStore = defineStore({
	id: 'form',
	state: () => ({
		acceptTermsAndConditions: false,
		additionalIncome: [],
		applicant2Email: '',
		applicant2Name: '',
		applicant2Phone: '',
		applicant2Relationship: '',
		applicationCompleted: false,
		applicationMaxStep: 0,
		applicationStep: 0,
		assets: [],
		authorId: 1452,
		creditCards: [],
		currentAddress: '',
		currentAddressMonths: 0,
		currentAddressYears: 0,
		currentEmployer: '',
		currentEmploymentMonths: 0,
		currentEmploymentType: '',
		currentEmploymentYears: 0,
		currentLivingArrangement: '',
		currentOccupation: '',
		currentWageAmount: 0,
		currentWageFrequency: '',
		dependents: 0,
		deposit: false,
		depositAmount: 0,
		dob: '',
		email: '',
		firstName: '',
		identificationDescription: '',
		identificationType: '',
		identificationUpload: [],
		identificationUpload2: [],
		incomeUpload: [],
		lastName: '',
		licenseExpiry: '',
		licenseNumber: '',
		licenseVersion: '',
		loanAmount: 0,
		loanPurpose: '',
		loanRepaymentFrequency: '',
		loanTerm: '12',
		loanType: '',
		loans: [],
		maritalStatus: '',
		middleName: '',
		mortgageBalance: 0,
		phone: '',
		previousAddress: '',
		previousAddressMonths: 0,
		previousAddressYears: 0,
		previousEmployer: '',
		previousEmploymentMonths: 0,
		previousEmploymentType: '',
		previousEmploymentYears: 0,
		previousOccupation: '',
		propertyValue: 0,
		referrer: '',
		rentMortgageAmount: 0,
		rentMortgageFrequency: '',
		residencyStatus: '',
		uid: '',
		visaExpiryDate: '',
	}),
})
