import {$BODY, $PAGE_HEADER} from '../util/global'
import Module from '../util/module'
import smoothscroll from '../modules/smoothscroll'

class HomeHeaderModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this._updateHeight()
	}

	onResizeX() {
		this._updateHeight()
	}

	onNavStart() {
		$PAGE_HEADER.removeClass('-past-landing')
	}

	onScroll() {
		if (!$BODY.hasClass('home-page')) return

		if (smoothscroll.top <= window.innerHeight - this.headerHeight) {
			$PAGE_HEADER.removeClass('-past-landing')
		} else {
			$PAGE_HEADER.addClass('-past-landing')
		}
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_updateHeight() {
		this.headerHeight = $PAGE_HEADER.prop('offsetHeight')
	}
}

export default new HomeHeaderModule()
