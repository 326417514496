import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from './smoothscroll'

class ProcessModule extends Module {
	// ----------------------------------------
	// Lifecycle Hooks
	// ----------------------------------------

	setup() {
		this.$el = $('.process')
		this.$steps = this.$el.find('.process__steps')
		this._render()
	}

	onNavEnd() {
		this.setup()
	}

	onScroll() {
		this._render()
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_render() {
		const elTop = this.$el.prop('offsetTop')
		const winTop = smoothscroll.top
		const winCenter = winTop + window.innerHeight / 2
		const diff = winCenter - elTop > 0 ? winCenter - elTop : 0

		this.percentScrolled = (diff / this.$el.prop('offsetHeight')) * 100

		if (this.percentScrolled < 0) {
			this.percentScrolled = 0
		}

		if (this.percentScrolled > 100) {
			this.percentScrolled = 100
		}

		// console.log(this.percentScrolled)

		if (this.percentScrolled < 33) {
			this.$el.addClass('-step-1')
			this.$el.removeClass('-step-2')
			this.$el.removeClass('-step-3')
			return
		}

		if (this.percentScrolled < 66) {
			this.$el.removeClass('-step-1')
			this.$el.addClass('-step-2')
			this.$el.removeClass('-step-3')
			return
		}

		this.$el.removeClass('-step-1')
		this.$el.removeClass('-step-2')
		this.$el.addClass('-step-3')
	}
}

export default new ProcessModule()
