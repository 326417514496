window.dataLayer = window.dataLayer || []

// Modules
import './modules/calculator'
import './modules/events'
import './modules/form'
import './modules/form-range'
import './modules/header'
import './modules/lazy'
import './modules/loader'
import './modules/parallax'
import './modules/process'
import './modules/router'
import './modules/scrolling-text'
import './modules/smoothscroll'
import './modules/sticky'
import './modules/testimonials'
import './modules/title'

// Pages
import './pages/home-header'
import './pages/home-landing'

// Demo
// import './_template'
