import smoothscroll from './smoothscroll'
import {isLG, isMD, isSM, isXL} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'

class ParallaxItem {
	constructor(item) {
		this.$item = $(item)
		this.speed = this.$item.data('parallax') || 1
		this.breakpoint = this.$item.data('parallax-bp') || 'xs'
	}

	// ----------------------------------------
	// Public methods
	// ----------------------------------------

	update() {
		if (!this._isEnabled()) {
			this.$item.style('transform', `translate3d(0, 0, 0)`)
			return
		}

		// Window
		const windowTop = smoothscroll.top
		const windowHeight = window.innerHeight
		const windowBottom = windowTop + windowHeight

		// Item
		const itemRect = this.$item.rect()
		const itemTop = itemRect.top + windowTop
		const itemHeight = itemRect.height
		const itemBottom = itemTop + itemHeight

		if (windowTop > itemBottom) return
		if (windowBottom < itemTop) return

		const pos = ((windowTop - itemTop + itemHeight) / windowHeight) * this.speed * 100 || 0

		this.$item.style('transform', `translate3d(0, ${-pos}px, 0)`)
	}

	// ----------------------------------------
	// Private methods
	// ----------------------------------------

	_isEnabled() {
		switch (this.breakpoint) {
			case 'xl':
				return isXL()

			case 'lg':
				return isLG()

			case 'md':
				return isMD()

			case 'sm':
				return isSM()

			default:
				return true
		}
	}
}

class ParallaxModule extends Module {
	// ----------------------------------------
	// Lifecycle hooks
	// ----------------------------------------

	setup() {
		this.$items = $('[data-parallax]:not([parallax-init])')
		this.$items.data('parallax-init', true)
		this.items = this.$items.any ? this.$items.map((item) => new ParallaxItem(item)) : []
	}

	onNavEnd() {
		this.setup()
	}

	onScroll() {
		this.items?.forEach((item) => item.update())
	}

	onResizeX() {
		this.items?.forEach((item) => item.update())
	}
}

export default new ParallaxModule()
