import {gsap} from 'gsap'
import axios from 'redaxios'
import {$WINDOW, $BODY, $PAGE_CONTENT, $PAGE_OVERLAYS} from '../util/global'
import Module from '../util/module'
import $ from '../util/query'
import smoothscroll from '../modules/smoothscroll'

class Router extends Module {
	setup() {
		this.loading = false

		$BODY.on('click', async (e) => {
			if (!e.target) return false

			const target = e.target
			const anchor = target.closest('a')

			if (!anchor) return false

			const href = anchor.getAttribute('href')
			const path = href.split(/[?#]+/)[0]
			const params = href.split(/[?#]+/)[1] || null
			const host = anchor.host

			const currentHref = window.location.href
			const currentPath = currentHref.split(/[?#]+/)[0]
			const currentParams = currentHref.split(/[?#]+/)[1] || null
			const currentHost = window.location.host

			if (href === '#') {
				// console.log('Link href matches #')
				e.preventDefault()
				return false
			}

			if (anchor.dataset.ignore || anchor.dataset.toggle) {
				// console.log('Link contains a forbidden data attribute')
				return false
			}

			if (anchor.getAttribute('role') === 'button') {
				// console.log('Link has a forbidden role')
				return false
			}

			if (anchor.hasAttribute('target')) {
				// console.log('Link has a forbidden attribute')
				return false
			}

			if (currentHost !== host) {
				// console.log('Link is an external link')
				return false
			}

			if (currentPath === path) {
				// console.log('Link is to current page')
				e.preventDefault()

				if (params && params !== currentParams) {
					window.history.pushState('', '', href)

					if (href.indexOf('#') > 0) $WINDOW.emit('hash-updated', new URLSearchParams(params))
					if (href.indexOf('?') > 0) $WINDOW.emit('params-updated', new URLSearchParams(params))
				}

				return false
			}

			if (href.indexOf('tel:') >= 0 || href.indexOf('mailto:') >= 0) {
				// console.log('Link is an email or tel link')
				return false
			}

			e.preventDefault()

			this.to(href)
		})
	}

	async to(href) {
		if (this.loading) return

		this.loading = true
		$WINDOW.emit('nav-started')
		$WINDOW.emit('loading')
		$BODY.removeClass('loaded').addClass('loading')

		this.updateActiveState(href)

		if (typeof window.history.pushState === 'function') {
			window.history.pushState('', '', href)
		}

		smoothscroll.enableScroll()
		smoothscroll.scrollToTop()

		const [data] = await Promise.all([this.fetchData(href), this.animateOut()])

		await this.render(data)

		$WINDOW.emit('content-updated')

		await this.animateIn()

		this.loading = false
		$WINDOW.emit('nav-ended')
		$WINDOW.emit('loaded')
		$BODY.removeClass('loading').addClass('loaded')
	}

	async animateOut() {
		const tl = gsap.timeline({paused: true})

		// tl.to(
		// 	$PAGE_HEADER,
		// 	{
		// 		duration: 0.75,
		// 		opacity: 0,
		// 	},
		// 	0,
		// )

		tl.to(
			$PAGE_CONTENT,
			{
				duration: 0.75,
				opacity: 0,
			},
			0,
		)

		tl.add(() => $BODY.addClass('content-hidden'))

		await tl.play()
	}

	async animateIn() {
		const tl = gsap.timeline({paused: true})

		// tl.to(
		// 	$PAGE_HEADER,
		// 	{
		// 		duration: 0.75,
		// 		opacity: 1,
		// 	},
		// 	0,
		// )

		tl.to(
			$PAGE_CONTENT,
			{
				duration: 0.75,
				opacity: 1,
			},
			0,
		)

		tl.add(() => $BODY.removeClass('content-hidden'))

		await tl.play()
	}

	async fetchData(href) {
		const {data} = await axios.get({
			method: 'get',
			url: href,
			validateStatus: (status) => {
				return status === 200 || status === 404
			},
		})

		return data
	}

	async render(data) {
		const parser = new DOMParser()
		const newDoc = parser.parseFromString(data, 'text/html')
		const newBody = newDoc.querySelector('body')
		const newContent = newDoc.querySelector('#page-content')
		const newOverlays = newDoc.querySelector('#page-overlays')
		const title = newDoc.querySelector('title').textContent

		// Replace the old content with the new content
		document.title = title

		$PAGE_CONTENT.html(...newContent.childNodes)
		$PAGE_OVERLAYS.html(...newOverlays.childNodes)
		$BODY.attr('class', newBody.getAttribute('class'))
		$BODY.addClass('loaded')

		// Trigger gtag
		// if (gtag && typeof gtag === 'function') {
		// 	if (!GTAG_ID) return

		// 	const href = window.location.href

		// 	if (!href.includes('.nz')) return

		// 	const path = href.split('.nz').pop()
		// 	// console.log('page-view', path)

		// 	gtag('config', GTAG_ID, {
		// 		page_title: title,
		// 		page_path: path,
		// 	})
		// }
	}

	updateActiveState(href) {
		const $links = $('.nav__link')

		$links.forEach((link) => {
			const $link = $(link)

			if (link.dataset.dropdown) {
				const $dropdown = $(link.dataset.dropdown)
				const $dropdownLinks = $dropdown.find(`a[href="${href}"]`)

				if ($dropdownLinks.any) {
					$link.addClass('-sub-page-active')
				} else {
					$link.removeClass('-sub-page-active')
				}

				return
			}

			if ($link.attr('href') === href) {
				$link.addClass('-active')
			} else {
				$link.removeClass('-active')
			}
		})
	}
}

export default new Router()
