import {gsap} from 'gsap'
import Module from '../util/module'
import $ from '../util/query'

class ScrollingText {
	constructor(el) {
		this.$el = $(el)
		this.text = this.$el.data('text')
		this.duration = parseInt(this.$el.data('duration')) || 20

		this._build()
		this._animate()
	}

	_build() {
		const template = `<span>${this.text}</span>`

		this.$el.append(template)
		this.$el.append(template)
		this.$el.append(template)
		this.$el.append(template)
	}

	_animate() {
		const $el1 = this.$el.find('span:nth-child(1)')
		const $el2 = this.$el.find('span:nth-child(2)')
		const $el3 = this.$el.find('span:nth-child(3)')
		const $el4 = this.$el.find('span:nth-child(4)')

		// Animate
		const tl1 = gsap
			.timeline({paused: true})
			.fromTo($el1, {xPercent: 0}, {ease: 'none', xPercent: -100})
			.fromTo($el1, {xPercent: 300}, {ease: 'none', xPercent: 200})
			.fromTo($el1, {xPercent: 200}, {ease: 'none', xPercent: 100})
			.fromTo($el1, {xPercent: 100}, {ease: 'none', xPercent: 0})

		const tl2 = gsap
			.timeline({paused: true})
			.fromTo($el2, {xPercent: 100}, {ease: 'none', xPercent: 0})
			.fromTo($el2, {xPercent: 0}, {ease: 'none', xPercent: -100})
			.fromTo($el2, {xPercent: 300}, {ease: 'none', xPercent: 200})
			.fromTo($el2, {xPercent: 200}, {ease: 'none', xPercent: 100})

		const tl3 = gsap
			.timeline({paused: true})
			.fromTo($el3, {xPercent: 200}, {ease: 'none', xPercent: 100})
			.fromTo($el3, {xPercent: 100}, {ease: 'none', xPercent: 0})
			.fromTo($el3, {xPercent: 0}, {ease: 'none', xPercent: -100})
			.fromTo($el3, {xPercent: 300}, {ease: 'none', xPercent: 200})

		const tl4 = gsap
			.timeline({paused: true})
			.fromTo($el4, {xPercent: 300}, {ease: 'none', xPercent: 200})
			.fromTo($el4, {xPercent: 200}, {ease: 'none', xPercent: 100})
			.fromTo($el4, {xPercent: 100}, {ease: 'none', xPercent: 0})
			.fromTo($el4, {xPercent: 0}, {ease: 'none', xPercent: -100})

		//prettier-ignore
		gsap
			.timeline({repeat: -1, paused: false})
			.add(tl1.play(), 0)
			.add(tl2.play(), 0)
			.add(tl3.play(), 0)
			.add(tl4.play(), 0)
			.duration(this.duration)
	}
}

class ScrollingTextModule extends Module {
	setup() {
		const $text = $('.scrolling-text')

		$text.forEach((el) => new ScrollingText(el))
	}

	onNavEnd() {
		this.setup()
	}
}

export default new ScrollingTextModule()
